<template>
  <div class="cointer">
    <div class="sititle">
      <div class="siti">{{ form.name }}</div>
      <div class="siback">
        <router-link :to="{path:'formalPend'}" class="siback">
        <div class="sibimg">
          <img
              alt=""
              src="http://cloud.cdmp.tech/88356f0bc29e9e2e3ca34fcbf8d6c98f.png"
          />
        </div>
        <div class="sicback" >返回</div>
         </router-link>
      </div>
    </div>
    <div class="stepcont">
      <el-steps :active="activeIndex" :space="200" finish-status="success">
        <el-step title="形式审核"></el-step>
        <el-step title="技术审核"></el-step>
        <el-step title="公示公告"></el-step>
        <el-step title="标识发放"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>
    <div class="sicontent">
      <div class="sicleft">
        <router-link :to="{path:'formDetail?project_id='+project_id}"
                     class="detailPush">
          <div class="siclkuai">
            <div class="siclleft">申报信息</div>
            <div class="siczhtai">
              <el-tag v-if="shenbao_check.pass==1" type="success">达标</el-tag>
              <el-tag v-else-if="shenbao_check.pass==2" type="danger">未达标</el-tag>
              <el-tag v-else-if="shenbao_check.pass==3" type="info">待定</el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <router-link :to="{path:'totalTable?project_id='+project_id }"
                     class="detailPush">
          <div class="siclkuai">
            <div class="siclleft">能效测评汇总表</div>
            <div class="siczhtai">
              <el-tag v-if="nengxiao_check.pass==1" type="success">达标</el-tag>
              <el-tag v-else-if="nengxiao_check.pass==2" type="danger">未达标</el-tag>
              <el-tag v-else-if="nengxiao_check.pass==3" type="info">待定</el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
        <div @click="gotoTable" class="detailPush">
          <div class="siclkuai">
            <div class="siclleft">
              <div class="siclname">能效测评报告</div>
              <div class="dowlend" @click.stop="downloadFile(3)">下载</div>
            </div>
            <div class="siczhtai">
              <el-tag v-if="baogao_check.pass==1" type="success">达标</el-tag>
              <el-tag v-else-if="baogao_check.pass==2" type="danger">未达标</el-tag>
              <el-tag v-else-if="baogao_check.pass==3" type="info">待定</el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </div>
        <router-link :to="{path:'ipendTable?project_id='+project_id }"
                     class="detailPush">
          <div class="siclkuai">
            <div class="siclleft">能效标识申报指标情况表</div>
            <div class="siczhtai">
              <el-tag v-if="zhibiao_check.pass==1" type="success">达标</el-tag>
              <el-tag v-else-if="zhibiao_check.pass==2" type="danger">未达标</el-tag>
              <el-tag v-else-if="zhibiao_check.pass==0" type="info">待定</el-tag>
              <el-tag v-else type="info">未审查</el-tag>
            </div>
          </div>
        </router-link>
       <div>
         <div class="siclkuai">
           <div class="siclleft">
             <div class="siclname">附件</div>
             <div class="dowlend" @click.stop="downloadFile(4)">下载</div>
           </div>
           <div class="siczhtai">
             <el-tag v-if="fujian_check.pass==1" type="success" @click="dialogVisible = true" style="cursor: pointer;">达标</el-tag>
             <el-tag v-else-if="fujian_check.pass==2" type="danger" @click="dialogVisible = true" style="cursor: pointer;">未达标</el-tag>
             <el-tag v-else-if="fujian_check.pass==3" type="info" @click="dialogVisible = true" style="cursor: pointer;">待定</el-tag>
             <el-tag v-else type="info" @click="dialogVisible = true" style="cursor: pointer;">未审查</el-tag>
<!--             <el-tag  type="info" @click="dialogVisible = true" style="cursor: pointer;">未审查</el-tag>-->
           </div>
         </div>
       </div>
      </div>
      <div class="siccenter">
        <div class="sicctitle">形式审查意见汇总</div>
        <div class="sicctxt">
          <p v-if="shenbao_check.idea">申报信息: {{shenbao_check.idea}}</p>
          <p v-if="nengxiao_check.idea">能效测评汇总表: {{nengxiao_check.idea}}</p>
          <p v-if="baogao_check.idea">能效测评报告: {{baogao_check.idea}}</p>
          <p v-if="zhibiao_check.idea">能效标识申报指标情况表: {{zhibiao_check.idea}}</p>

<!--          <el-input-->
<!--              disabled-->
<!--              v-model="yijian"-->
<!--              placeholder="请输入内容"-->
<!--              type="textarea"-->
<!--          >-->
<!--          </el-input>-->
        </div>
      </div>
      <div class="sicright">
        <div class="sicctitle">历史记录</div>
        <div class="sicrtxt">
          <el-timeline :reverse="reverse">
            <el-timeline-item
                v-for="(activity, index) in log"
                :key="index"
                :timestamp="activity.created_at"
            >
              {{ activity.type }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div class="scibtn">
      <el-button type="primary" @click="check">保存</el-button>
      <el-button type="primary" @click="back">打回修改</el-button>
      <el-button type="primary" @click="pass_pro">通过</el-button>
    </div>
    <!-- 弹窗 -->
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
   <el-radio-group v-model="fujian_check.pass">
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="check_fujian">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>
<script>
import {checkBack, checkPass, checkSave, projectTableInfo} from "../../../../api/project";

export default {
  data() {
    return {
      reverse: true,
       dialogVisible: false,
      idea: "",
      activeIndex: 0,
      activities: [
        {
          content: "提交",
          timestamp: "2018-04-15",
        },
        {
          content: "打回修改",
          timestamp: "2018-04-13",
        },
        {
          content: "提交",
          timestamp: "2018-04-11",
        },
      ],
      project_id: '',
      form: {},
      checks: [],
      shenbao_check: {},
      nengxiao_check: {},
      baogao_check: {},
      zhibiao_check: {},
      fujian_check: {},
      zong_check: {},

      pass: '',
      yijian: '',
      log: [],
    };
  },
  mounted() {
    this.project_id = this.$route.query.id;
    if (this.$route.query.active){
      this.activeIndex = this.$route.query.active
    }
    this.getInfo();
  },
  methods: {
// goBack(){
//    this.$router.go(-1);
// },

    gotoTable(){
      this.$router.push({path:'reportTable?project_id='+this.project_id})

    },

    //下载文件
    downloadFile(type){
      let files = this.form.files;
      let download_files = [];
      if (files && files.length>0){
        for (let i=0;i<files.length;i++){
          if (files[i].type==type){
            download_files.push(files[i]);
          }
        }
        if (download_files && download_files.length>0){
          for (let i=0;i<download_files.length;i++){
            const iframe = document.createElement("iframe");
            iframe.style.display = "none"; // 防止影响页面
            iframe.style.height = 0; // 防止影响页面
            iframe.src = download_files[i].url;
            document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
            // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
            setTimeout(()=>{
              iframe.remove();
            }, 5 * 60 * 1000);
          }
        }else {
          this.$message.warning('没有文件可下载！');
        }
      }else {
        this.$message.warning('没有文件可下载！');
      }
    },

    //附件审核
    check_fujian() {
      let params = {
        idea: '',
        pass: this.fujian_check.pass,
        project_id: this.project_id,
        type: 5,
        token: this.$store.state.token
      };
      let that = this;
      checkSave(params).then(res => {
        console.log(res);
        that.$message.success('保存成功');
        that.dialogVisible = false;
      })
    },


    //通过
    pass_pro() {
      if (this.form.batch && this.form.batch!=''){
        let params = {
          idea: this.idea,
          pass: 1,
          project_id: this.project_id,
          type: 6,
          token: this.$store.state.token
        };
        if (this.shenbao_check.pass!=1){
          this.$message.warning('需要前面所有步骤达标！')
          return false;
        }
        if (this.nengxiao_check.pass!=1){
          this.$message.warning('需要前面所有步骤达标！')
          return false;
        }
        if (this.baogao_check.pass!=1){
          this.$message.warning('需要前面所有步骤达标！')
          return false;
        }
        if (this.zhibiao_check.pass!=1){
          this.$message.warning('需要前面所有步骤达标！')
          return false;
        }
        if (this.fujian_check.pass!=1){
          this.$message.warning('需要前面所有步骤达标！')
          return false;
        }

        let that = this;
        checkPass(params).then(res => {
          console.log(res);
          that.$message.success('成功通过');
          that.$router.push({
            path: "./formalPend"
          })
        })
      }else {
        this.$message.warning('请先建立批次');
      }

    },

    //打回
    back() {
      let params = {
        idea: this.idea,
        pass: 2,
        project_id: this.project_id,
        type: 6,
        token: this.$store.state.token
      };
      let that = this;
      checkBack(params).then(res => {
        console.log(res);
        that.$message.success('打回成功');
        that.$router.push({
          path: "./formalPend"
        })
      })
    },


    check() {
      let params = {
        idea: this.idea,
        pass: 3,
        project_id: this.project_id,
        type: 6,
        token: this.$store.state.token
      };
      let that = this;
      checkSave(params).then(res => {
        console.log(res);
        that.$message.success('保存成功');
        that.$router.push({
          path: "./formalPend"
        })
      })
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then(res => {
        console.log(res);
        that.form = res.data;
        that.checks = res.data.checks;
        that.log = res.data.log;

        let yijian_total = '';

        for (let i = 0; i < that.checks.length; i++) {
          // if (that.checks[i].idea && that.checks[i].idea!='' ){
          //   yijian_total += that.checks[i].idea+'\n';
          // }
          if (that.checks[i].type == 1) {
            that.shenbao_check = that.checks[i];
          } else if (that.checks[i].type == 2) {
            that.nengxiao_check = that.checks[i];
          } else if (that.checks[i].type == 3) {
            that.baogao_check = that.checks[i];
          } else if (that.checks[i].type == 4) {
            that.zhibiao_check = that.checks[i];
          } else if (that.checks[i].type == 5) {
            that.fujian_check = that.checks[i];
          } else if (that.checks[i].type == 6) {
            that.zong_check = that.checks[i];
            that.idea = that.zong_check.idea;
          }

        }
        that.yijian = yijian_total;
      })
    },
  },
};
</script>
<style scoped>
/deep/.el-textarea>textarea{
    height: 400px;
}
/deep/ .el-textarea__inner {
  border: 0;
}
</style>
<style lang="scss" scoped>
.detailPush {
  text-decoration: none;
}

.sititle {
  margin: 47px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .siti {
    margin-left: 310px;
    font-size: 30px;
    font-family: Adobe Heiti Std;
    color: #333333;
  }

  .siback {
    display: flex;
    flex-direction: row;

    img {
      width: 28px;
      height: 28px;
    }

    .sicback {
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      color: #3086fb;
      margin-left: 6px;
    }
  }
}

.stepcont {
  width: 1150px;
  margin: 50px auto;
}

.sicontent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 75px;

  .sicleft {
    .siclkuai {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .siclleft {
        width: 430px;
        height: 80px;
        line-height: 80px;
        background: #ffffff;
        border-radius: 4px;
        border-left: 10px solid #54acff;
        margin-bottom: 17px;
        padding-left: 32px;
        font-size: 24px;
        color: #282828;
        font-family: Adobe Heiti Std;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        text-decoration: none;

        .siclname {
          text-decoration: none;
        }

        .dowlend {
          text-align: right;
          margin-right: 15px;
          color: #3086fb;
          font-size: 14px;
        }
      }

      .siczhtai {
        margin: 30px 32px;
      }
    }
  }

  .siccenter {
    .sicctitle {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      margin-bottom: 22px;
    }

    .sicctxt {
      width: 660px;
      height: 400px;
      padding-left: 10px;
      background: #ffffff;
      box-shadow: 0px 3px 8px 1px rgba(204, 204, 204, 0.3);
      border-top: 10px solid #4aa1fe;
      border-radius: 4px;
    }
  }

  .sicright {
    .sicctitle {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      margin-bottom: 22px;
    }

    .sicrtxt {
      width: 340px;
      height: 370px;
      overflow-y: scroll;
      background: #ffffff;
      box-shadow: 0px 3px 8px 1px rgba(204, 204, 204, 0.3);
      border-radius: 4;
      padding: 20px 0;
    }
    .sicrtxt::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}

.scibtn {
  margin-top: 100px;
  text-align: center;
}
</style>
